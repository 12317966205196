import { ResultSet, TableColumn } from '@cubejs-client/core';
import { useI18n } from 'vue-i18n';
import { VDataTableVirtual } from 'vuetify/lib/components/index.mjs';
import { TimeDimensionGranularity } from '..';
import { unflatten } from 'flat';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { dateToGranularity } from './useChart';
import _ from 'lodash';
import moment from 'moment';

export function useResultSet(_resultSet?: ResultSet) {
    const { t } = useI18n();
    const $route = useRoute();
    const resultSet = ref<ResultSet | undefined>(_resultSet);

    function setResultSet(rs: ResultSet | undefined) {
        resultSet.value = rs;
    }

    const granularity = computed(() => {
        const query = unflatten($route.query);
        return _.get(query, 'timeDimensions[0].granularity', 'day') as TimeDimensionGranularity;
    });

    function parseHeaderTitle(header: TableColumn): string {
        const headerSplit = header.key.split('.');
        const lastKey = headerSplit[headerSplit.length - 1];
        if (Object.values(TimeDimensionGranularity).includes(lastKey as TimeDimensionGranularity)) {
            return t(`cube.timeGranularity.${lastKey}`);
        }

        return t(`cube.cubes.${header.key}`);
    }

    const headers = computed<VDataTableVirtual['$props']['headers']>(() => {
        if (!resultSet.value) {
            return [];
        }

        return resultSet.value.tableColumns().map((c) => ({
            key: c.key,
            title: parseHeaderTitle(c),
            align: 'start',
        }));
    });

    function parseValue(row: any, header: TableColumn) {
        if (header.type == 'time') {
            return dateToGranularity(row[header.key], granularity.value);
        }

        return row[header.key];
    }

    const items = computed<VDataTableVirtual['$props']['items']>(() => {
        if (!resultSet.value) {
            return [];
        }

        return resultSet.value.tablePivot().map((row) => {
            const item: Record<string, any> = {};

            // @ts-ignore
            resultSet.value.tableColumns().forEach((header) => {
                // @ts-ignore
                item[header.key] = parseValue(row, header);
            });

            return item;
        });
    });

    return {
        headers,
        items,
        setResultSet,
    };
}
