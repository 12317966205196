import { ResultSet } from '@cubejs-client/core';
import { computed, ref } from 'vue';
import charts, { ChartType } from './charts';
import { TimeDimensionGranularity } from '../index';
import moment from 'moment-timezone';
import { EChartsOption } from 'echarts';
import { useI18n } from 'vue-i18n';
import { VDataTableVirtual } from 'vuetify/lib/components/index.mjs';
import { useRoute } from 'vue-router';

const defaultOptions: EChartsOption = {
    legend: {
        type: 'scroll',
    },
    grid: {
        containLabel: true,
    },
    toolbox: {
        top: '25px',
        feature: {
            saveAsImage: {
                show: true,
            },
            // magicType: { show: true, type: ['line'] },
            restore: { show: true },
            dataZoom: {
                yAxisIndex: 'none',
            },
        },
    },
    backgroundColor: '#f4f4f4',
};

export function useChart() {
    const { t } = useI18n();
    const $route = useRoute();

    const resultSet = ref<ResultSet | null>(null);
    const chartType = ref<ChartType>('bar');

    const chartTypes = computed(() => {
        return Object.keys(charts).map((key) => {
            return {
                value: key as ChartType,
                text: t(`cube.charts.${key}`),
            };
        });
    });

    function setResultSet(rs: ResultSet) {
        resultSet.value = rs;
    }

    function setChartType(type: ChartType) {
        chartType.value = type;
    }

    const options = computed<EChartsOption | VDataTableVirtual['$props']>(() => {
        return charts[chartType.value](resultSet.value as ResultSet, { defaultOptions, t, $route });
    });

    return {
        resultSet,
        setResultSet,
        chartType,
        setChartType,
        options,
        chartTypes,
    };
}

export const dateToGranularity = (date: Date | string, granularity: TimeDimensionGranularity) => {
    const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const d = moment(date);

    if (TIMEZONE) d.tz(TIMEZONE);

    switch (granularity) {
        case 'year':
            return d.format('YYYY');
        case 'quarter':
            return d.format('YYYY-Q');
        case 'month':
            return d.format('YYYY-MM');
        case 'week':
            return d.format('YYYY-[W]WW');
        case 'day':
            return d.format('YYYY-MM-DD');
        // case 'hour':
        //     return moment(date).format('HH');
        // case 'minute':
        //     return moment(date).format('HH:mm');
        // case 'second':
        //     return moment(date).format('HH:mm:ss');
        default:
            return date;
    }
};
