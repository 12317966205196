<template>
    <v-container fluid>
        <QueryBuilder
            class="tw-mb-8"
            :loading="pageLoad"
            :result-set="resultSet"
            @load="onLoad"
            @clear="onClear" />

        <Chart
            v-if="resultSet"
            :result-set="resultSet"
            :chart="chart" />
        <!-- <div
            id="resultTable"
            v-html="docHtml"></div> -->
    </v-container>
</template>

<script setup lang="ts">
    import _ from 'lodash';
    import axios from '@axios';
    import QueryBuilder from './QueryBuilder.vue';
    import Chart from './Chart.vue';
    import { useRoute } from 'vue-router';
    import { unflatten } from 'flat';
    import { useCube } from './composables/useCube';
    import { Query, ResultSet } from '@cubejs-client/core';
    import { ref } from 'vue';
    import { ChartType, chartTypes } from './composables/charts';
    import qs from 'qs';
    import { SVGRenderer, CanvasRenderer } from 'echarts/renderers';
    import { LegendComponent, GridComponent, TooltipComponent, DataZoomComponent, ToolboxComponent, MarkLineComponent, TitleComponent, VisualMapComponent } from 'echarts/components';
    import { BarChart, LineChart, PieChart, ScatterChart, RadarChart, HeatmapChart } from 'echarts/charts';
    import { use } from 'echarts/core';
    import { computed } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';

    const $route = useRoute();
    const $cube = useCube();
    const $alert = useAlert();
    const { t } = useI18n();

    const resultSet = ref<ResultSet>();
    const chart = ref<ChartType>('bar');
    const docHtml = ref('');
    const isLoading = ref(false);

    async function onLoad() {
        const query = unflatten($route.query) as Query & { chart?: ChartType };
        chart.value = query.chart || chartTypes[0];
        delete query.chart;
        isLoading.value = true;
        try {
            resultSet.value = await $cube.cube.load(query);
        } catch (error: any) {
            $alert.showAlert({
                title: t('shared.errorOcurred'),
                text: _.get(error, 'response.error.message', _.get(error, 'message', t('shared.unknown_error'))),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }

        // await axios.get(
        //     `/cube-js/export-html/load?${qs.stringify({
        //         query,
        //         queryType: 'multi',
        //     })}`,
        // ).then((res) => {
        //     docHtml.value = res.data;
        //     // resultSet.value = res.data;
        // });
    }

    function onClear() {
        resultSet.value = undefined;
        docHtml.value = '';
    }

    const pageLoad = computed(() => {
        return isLoading.value || $cube.isLoading.value;
    });

    use([
        SVGRenderer,
        CanvasRenderer,
        LegendComponent,
        GridComponent,
        TooltipComponent,
        DataZoomComponent,
        ToolboxComponent,
        BarChart,
        LineChart,
        PieChart,
        ScatterChart,
        RadarChart,
        MarkLineComponent,
        TitleComponent,
        HeatmapChart,
        VisualMapComponent,
    ]);
</script>

<style scoped></style>
