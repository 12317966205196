<template>
    <v-row
        id="query-builder"
        dense
        class="tw-mb-2 tw-text-sm">
        <v-col
            v-if="$cube.measures.value.length"
            cols="12"
            md="6"
            lg="4">
            <label class="tw-font-medium tw-uppercase">{{ $t('cube.measures') }}</label>
            <v-autocomplete
                multiple
                clearable
                :menu-props="menuProps"
                density="compact"
                v-model="measures"
                :items="$cube.measures.value"
                item-title="title"
                item-value="name">
            </v-autocomplete>
        </v-col>
        <v-col
            v-if="$cube.dimensions.value.length"
            cols="12"
            md="6"
            lg="4">
            <label class="tw-font-medium tw-uppercase">{{ $t('cube.dimensions') }}</label>
            <v-autocomplete
                :multiple="chart == 'table'"
                clearable
                :menu-props="menuProps"
                density="compact"
                v-model="dimensions"
                :items="$cube.dimensions.value"
                item-title="title"
                item-value="name">
            </v-autocomplete>
        </v-col>
        <v-col
            v-if="$cube.segments.value.length"
            cols="12"
            md="6"
            lg="4">
            <label class="tw-font-medium tw-uppercase">{{ $t('cube.segments') }}</label>
            <v-autocomplete
                multiple
                clearable
                :menu-props="menuProps"
                density="compact"
                v-model="segments"
                :items="$cube.segments.value"
                item-title="title"
                item-value="name">
            </v-autocomplete>
        </v-col>
        <v-col
            v-if="$cube.timeDimensions.value.length"
            cols="12">
            <label class="tw-font-medium tw-uppercase">{{ $t('cube.time') }}</label>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
                <v-autocomplete
                    clearable
                    density="compact"
                    :menu-props="menuProps"
                    v-model="timeDimension"
                    class="tw-max-w-72"
                    :items="$cube.timeDimensions.value"
                    item-title="title"
                    item-value="name">
                </v-autocomplete>
                <template v-if="timeDimension">
                    <span>{{ $t('cube.for') }}</span>
                    <v-autocomplete
                        clearable
                        :menu-props="menuProps"
                        density="compact"
                        v-model="dateRanges"
                        class="tw-max-w-72"
                        :items="$cube.dateRanges.value"
                        item-value="dateRange"
                        item-title="title">
                    </v-autocomplete>
                    <v-text-field
                        v-if="dateRanges == 'custom'"
                        readonly
                        :value="customRangeText"
                        density="compact"
                        @click="showDatePicker = !showDatePicker"
                        class="tw-min-w-52 tw-max-w-72"
                        dense
                        placeholder="Custom range">
                    </v-text-field>
                    <!-- <VueDatePicker
                        v-if="dateRanges == 'custom'"
                        range
                        v-model="customRange" /> -->
                    <span>{{ $t('cube.by') }}</span>
                    <v-autocomplete
                        clearable
                        :menu-props="menuProps"
                        density="compact"
                        class="tw-max-w-72"
                        v-model="timeGranularity"
                        :items="$cube.timeGranularity.value"
                        item-value="value"
                        item-title="title">
                    </v-autocomplete>
                    <v-dialog
                        v-model="showDatePicker"
                        @update:model-value="_customRange = []">
                        <div class="tw-flex tw-items-center tw-justify-center">
                            <v-date-picker
                                v-model="_customRange"
                                multiple="range"
                                color="primary">
                                <template #actions>
                                    <v-btn
                                        variant="text"
                                        @click="onCancelDate"
                                        >{{ $t('shared.cancel') }}</v-btn
                                    >
                                    <v-btn
                                        variant="text"
                                        @click="onSetDate"
                                        >{{ $t('shared.ok') }}</v-btn
                                    >
                                </template>
                            </v-date-picker>
                        </div>
                    </v-dialog>
                </template>
            </div>
        </v-col>
        <v-col cols="12">
            <label class="tw-font-medium tw-uppercase">{{ $t('cube.filters') }}</label>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
                <v-autocomplete
                    clearable
                    v-model="filter.member"
                    :menu-props="menuProps"
                    class="tw-min-w-52 tw-max-w-72"
                    density="compact"
                    :items="$cube.filterMembers.value"
                    item-title="title"
                    item-value="name">
                </v-autocomplete>
                <v-autocomplete
                    v-if="filter.member"
                    v-model="filter.operator"
                    clearable
                    :menu-props="menuProps"
                    density="compact"
                    class="tw-min-w-52 tw-max-w-72"
                    :items="$cube.binaryOperators.value"
                    item-title="title"
                    item-value="value">
                </v-autocomplete>
                <v-text-field
                    v-if="filter.member"
                    v-model="filter.values[0]"
                    density="compact"
                    class="tw-min-w-52 tw-max-w-72"
                    dense>
                </v-text-field>
            </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            lg="4">
            <label class="tw-font-medium tw-uppercase">{{ $t('cube.chartType') }}</label>
            <v-autocomplete
                clearable
                v-model="chart"
                density="compact"
                class="tw-max-w-72"
                :items="$chart.chartTypes.value"
                item-value="value"
                item-title="text"></v-autocomplete>
        </v-col>
        <v-col class="tw-justify-left tw-flex tw-items-end tw-gap-4">
            <v-btn
                :loading="props.loading"
                @click="loadData"
                color="primary"
                >{{ $t('shared.load') }}</v-btn
            >
            <v-btn @click="clearQuery">{{ $t('shared.clear') }}</v-btn>
            <v-btn
                :loading="loadingExport"
                @click="exportCSV"
                color="blue"
                >Exportar</v-btn
            >
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import { ref, computed } from 'vue';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import { useCube } from './composables/useCube';
    import { BinaryFilter, DateRange, Query, ResultSet } from '@cubejs-client/core';
    import { watchEffect } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { flatten, unflatten } from 'flat';
    import _ from 'lodash';
    import { useChart } from './composables/useChart';
    import { ChartType } from './composables/charts';
    import { useResultSet } from './composables/useResultSet';
    import qs from 'qs';
    import moment from 'moment-timezone';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();
    const $alert = useAlert();
    const $cube = useCube();
    const $router = useRouter();
    const $route = useRoute();
    const $chart = useChart();
    const $resultSet = useResultSet();
    const loadingExport = ref(false);

    const props = defineProps<{
        loading: boolean;
        resultSet?: ResultSet;
    }>();

    const $emit = defineEmits(['load', 'clear']);

    const measures = ref<string[]>([]);
    const dimensions = ref<string[]>([]);
    const segments = ref<string[]>([]);
    const timeDimension = ref<string>();
    const timeGranularity = ref<string>($cube.timeGranularity.value[0].value);
    const dateRanges = ref<DateRange | null>($cube.dateRanges.value[2].dateRange);
    const customRange = ref<string[] | Date[]>([]);
    const _customRange = ref<Date[]>([]);
    const filter = ref<BinaryFilter>({
        member: '',
        operator: 'equals',
        values: [],
    });
    const showDatePicker = ref(false);
    const chart = ref<ChartType>($chart.chartTypes.value[$chart.chartTypes.value.length - 1].value);

    const menuProps = {
        class: 'query-builder-autocomplete',
    };

    const query = computed(() => {
        const q: Query = {};

        if (chart.value) {
            // @ts-ignore
            q.chart = chart.value;
        }

        if (measures.value) {
            if (!Array.isArray(measures.value)) {
                q.measures = [measures.value];
            } else if (measures.value.length) {
                q.measures = measures.value;
            }
        }

        if (dimensions.value) {
            if (!Array.isArray(dimensions.value)) {
                q.dimensions = [dimensions.value];
            } else if (dimensions.value.length) {
                q.dimensions = dimensions.value;
            }
        }

        if (segments.value) {
            if (!Array.isArray(segments.value)) {
                q.segments = [segments.value];
            } else if (segments.value.length) {
                q.segments = segments.value;
            }
        }

        if (timeDimension.value) {
            const tq: any = {
                dimension: timeDimension.value,
            };

            if (timeGranularity.value != 'withoutGrouping') {
                tq.granularity = timeGranularity.value;
            }

            if (dateRanges.value && dateRanges.value == 'custom') {
                tq.dateRange = customRange.value;
            } else if (dateRanges.value) {
                tq.dateRange = dateRanges.value;
            }

            if (dateRanges.value || timeGranularity.value != 'withoutGrouping') {
                q.timeDimensions = [tq];
            }
        }

        if (filter.value.member && filter.value.operator && filter.value.values.length) {
            q.filters = [
                {
                    member: filter.value.member,
                    operator: filter.value.operator,
                    values: filter.value.values,
                },
            ];
        }

        return q as Query;
    });

    const customRangeText = computed(() => {
        if (Array.isArray(customRange.value) && customRange.value.length) {
            return customRange.value.map((date) => moment(date).format('YYYY-MM-DD')).join(' - ');
        }
        return '';
    });

    async function loadData() {
        $emit('load');
    }

    function clearQuery() {
        measures.value = [];
        dimensions.value = [];
        segments.value = [];
        timeDimension.value = undefined;
        timeGranularity.value = $cube.timeGranularity.value[0].value;
        dateRanges.value = $cube.dateRanges.value[2].dateRange;

        $emit('clear');
    }

    async function exportCSV() {
        loadingExport.value = true;
        const q = _.cloneDeep(query.value);
        // @ts-ignore
        delete q.chart;
        try {
            // open link in new tab
            const file = await axios.get(`/cube-js/export-csv/load?${qs.stringify({ query: q, queryType: 'multi' })}`);
            const ts = moment().format('YYYY-MM-DD_HH-mm-ss');
            // make the browser download the file
            const url = window.URL.createObjectURL(new Blob([file.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pleno_${ts}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            $alert.showAlert({
                title: 'Error',
                text: _.get(error, 'response.error.message', _.get(error, 'message', t('shared.unknown_error'))),
                type: 'error',
            });
        } finally {
            loadingExport.value = false;
        }
    }

    function onCancelDate() {
        _customRange.value = [];
        showDatePicker.value = false;
    }

    function onSetDate() {
        const firstDate = moment(_customRange.value[0]).startOf('day');
        // set time at 00:00:00

        const lastDate = moment(_customRange.value[_customRange.value.length - 1]).endOf('day');
        // set time at 23:59:59

        customRange.value = [firstDate.format('YYYY-MM-DDTHH:mm:ss.SSS'), lastDate.format('YYYY-MM-DDTHH:mm:ss.SSS')];

        showDatePicker.value = false;
    }

    function onSetFilterValue(value: string) {
        filter.value.values = [value];
    }

    watchEffect(() => {
        //@ts-ignore
        $router.push({
            query: flatten(query.value),
        });
    });

    watchEffect(() => {
        $resultSet.setResultSet(props.resultSet);
    });

    try {
        if ($route.query) {
            const _query: any = unflatten($route.query);
            measures.value = _.get(_query, 'measures', []);
            dimensions.value = _.get(_query, 'dimensions', []);
            segments.value = _.get(_query, 'segments', []);
            timeDimension.value = _.get(_query, 'timeDimensions[0].dimension');
            timeGranularity.value = _.get(_query, 'timeDimensions[0].granularity', $cube.timeGranularity.value[0].value);
            const dR = _.get(_query, 'timeDimensions[0].dateRange', $cube.dateRanges.value[2].dateRange);
            if (Array.isArray(dR)) {
                customRange.value = dR;
                dateRanges.value = 'custom';
            } else {
                dateRanges.value = dR;
            }
            chart.value = _.get(_query, 'chart', $chart.chartTypes.value[$chart.chartTypes.value.length - 1].value);
            const f = _.get(_query, 'filters[0]');
            if (f) {
                filter.value.member = f.member;
                filter.value.operator = f.operator;
                filter.value.values = f.values;
            }
        }
    } catch (error) {
        console.error(error);
    }
</script>

<style>
    #query-builder .v-field,
    .query-builder-autocomplete .v-list-item-title {
        @apply tw-text-sm;
    }
</style>
