export enum Installation_Income {
    Variable = 'variable',
    Fixed = 'fixed',
}
export enum Installation_BusinessType {
    Ppa = 'PPA',
    Epc = 'EPC',
}
export enum Installation_Type {
    Battery = 'battery',
    Panel = 'panel',
    Cve = 'cve',
    PanelCve = 'panel/cve',
}

export interface Media<P extends boolean = true> {
    id?: number;
    name: string;
    alternativeText?: string;
    caption?: string;
    width?: number | null;
    height?: number | null;
    formats: { thumbnail: MediaFormat; small: MediaFormat; medium: MediaFormat; large: MediaFormat };
    hash: string;
    ext?: string;
    mime: string;
    size: number | null;
    url: string;
    previewUrl?: string;
    provider: string;
    provider_metadata?: any;
    folderPath: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Role<P extends boolean = true> {
    id?: number;
    name: string;
    description?: string;
    type?: string;
    users?: P extends true ? User<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface User<P extends boolean = true> {
    id?: number;
    username: string;
    email: string;
    provider?: string;
    password?: string;
    resetPasswordToken?: string;
    confirmationToken?: string;
    confirmed?: boolean;
    blocked?: boolean;
    role?: P extends true ? Role<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Client<P extends boolean = true> {
    id?: number;
    nif?: string;
    name?: string;
    installations?: P extends true ? Installation<P>[] : number[];
    accountNumber?: string;
    country?: string;
    city?: string;
    yearlyCosts?: P extends true ? YearlyCost<P>[] : number[];
    postalCode?: string;
    agent?: boolean;
    agentInstallations?: P extends true ? Installation<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Configuration<P extends boolean = true> {
    id?: number;
    emailToNoSelfConsumed?: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Installation<P extends boolean = true> {
    id?: number;
    ref?: string;
    name?: string;
    timezone?: string;
    sysCreated?: Date | null;
    lastUpdated?: Date | null;
    currency?: string;
    eMonthUnit?: string;
    eTodayUnit?: string;
    eTotalUnit?: string;
    platform?: string;
    firstFetchedDate?: Date | null;
    lastFetchedDate?: Date | null;
    country?: string;
    client?: P extends true ? Client<P> | null : number | null;
    fixedValue?: number | null;
    invoiceIssuanceCost?: number;
    minMonthlyBilling?: boolean;
    income?: Installation_Income;
    businessType?: Installation_BusinessType;
    continueFetching?: boolean;
    lastFetchError?: any;
    fetchErrorAt?: Date | null;
    lat?: number | null;
    long?: number | null;
    panelAreas?: P extends true ? PanelArea<P>[] : number[];
    yearlyCosts?: P extends true ? YearlyCost<P>[] : number[];
    plenoCost?: number | null;
    investmentCost?: number | null;
    investmentDate?: Date | null;
    installationEndDate?: Date | null;
    inverterTotalPower?: number | null;
    active?: boolean;
    type?: Installation_Type;
    notes?: string;
    agent?: P extends true ? Client<P> | null : number | null;
    installationStartDate?: Date | null;
    contractStartDate?: Date | null;
    contractTime?: number | null;
    pvTotalPower?: number | null;
    contractEndDate?: Date | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface PanelArea<P extends boolean = true> {
    id?: number;
    azimuth?: number | null;
    declination?: number | null;
    panelQuantity?: number | null;
    panelPower?: number | null;
    name?: string;
    installation?: P extends true ? Installation<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Platform<P extends boolean = true> {
    id?: number;
    platform?: string;
    lastRun?: Date | null;
    lastEndAt?: Date | null;
    page?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Translation<P extends boolean = true> {
    id?: number;
    json?: any;
    createdAt?: Date | null;
    updatedAt?: Date | null;
    locale: string;
    localizations?: Translation[];
}

export interface YearlyCost<P extends boolean = true> {
    id?: number;
    startDate?: Date | null;
    endDate?: Date | null;
    fixedPrice?: number | null;
    installation?: P extends true ? Installation<P> | null : number | null;
    client?: P extends true ? Client<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface MediaFormat {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    width: number;
    height: number;
    size: number;
    path: string;
    url: string;
}
export interface ContentTypes<P extends boolean = true> {
    Media: Media<P>;
    Role: Role<P>;
    User: User<P>;
    Client: Client<P>;
    Configuration: Configuration<P>;
    Installation: Installation<P>;
    PanelArea: PanelArea<P>;
    Platform: Platform<P>;
    Translation: Translation<P>;
    YearlyCost: YearlyCost<P>;
}
export enum ContentTypesUID {
    Media = 'plugin::upload.file',
    Role = 'plugin::users-permissions.role',
    User = 'plugin::users-permissions.user',
    Client = 'api::client.client',
    Configuration = 'api::configuration.configuration',
    Installation = 'api::installation.installation',
    PanelArea = 'api::panel-area.panel-area',
    Platform = 'api::platform.platform',
    Translation = 'api::translation.translation',
    YearlyCost = 'api::yearly-cost.yearly-cost',
}
export type ContentType<T extends keyof ContentTypes, P extends boolean = true> = ContentTypes<P>[T];

export interface APIResponseMany<T extends keyof ContentTypes> {
    data: ContentType<T>[];
    meta: {
        pagination: {
            page: number;
            pageSize: number;
            pageCount: number;
            total: number;
        };
    };
}

export interface APIResponseSingle<T extends keyof ContentTypes> {
    data: ContentType<T>;
}

export interface APIRequestParams<T extends keyof ContentTypes> {
    populate?: any;
    fields?: (keyof ContentType<T, false>)[];
    locale?: string | string[];
    filters?: any;
    sort?:
        | `${string & keyof ContentType<T, false>}:asc`
        | `${string & keyof ContentType<T, false>}:desc`
        | (`${string & keyof ContentType<T, false>}:asc` | `${string & keyof ContentType<T, false>}:desc`)[];
    pagination?: {
        page?: number;
        pageSize?: number;
    };
}
