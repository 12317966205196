<template>
    <v-app>
        <router-view />
        <PromptUpdate />
    </v-app>
</template>

<script setup lang="ts">
    import PromptUpdate from '@/components/PromptUpdate.vue';
    import { useAuth } from '@/composables/useAuth';
    import { useLocale } from './composables/useLocale';
    import { getTranslations } from './api/translations';
    import { useI18n } from 'vue-i18n';

    useAuth().checkAuth();

    const i18n = useI18n({ useScope: 'global' });
    const $locale = useLocale();
    $locale.setLocale('pt');

    async function fetchTranslations() {
        const { data } = await getTranslations({
            populate: 'localizations',
        });

        i18n.mergeLocaleMessage(data.data.locale, data.data.json);

        for (const locale of data.data.localizations ?? []) {
            i18n.mergeLocaleMessage(locale.locale, locale.json);
        }
    }

    if (import.meta.env.PROD) fetchTranslations();
</script>
