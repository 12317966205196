import { nextTick, onMounted, ref, watch } from 'vue';
import { useDisplay as useVDisplay } from 'vuetify/lib/framework.mjs';

export const useDisplay = (toolbarId: string) => {
    const { mobile: isMobile, height: displayHeight, width: displayWidth } = useVDisplay();

    const toolbarHeight = ref('0px');
    async function onDisplayChange() {
        await nextTick();
        const toolbar = document.getElementById(toolbarId);
        if (toolbar) {
            toolbarHeight.value = toolbar.clientHeight + 'px';
        }
    }
    watch([displayHeight, displayWidth], () => {
        onDisplayChange();
    });

    onMounted(() => {
        onDisplayChange();
    });

    return {
        toolbarHeight,
    };
};
