import cubejs, { Cube, DateRange, TCubeDimension } from '@cubejs-client/core';
// @ts-ignore
import { TimeDimensionGranularity, BinaryOperator, UnaryOperator } from '../index.d.ts';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/store/auth.js';
import { useAlert } from '@/composables/useAlert.js';
import _ from 'lodash';

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const defaultTimeQueries: {
    title: string;
    dateRange: DateRange | null;
}[] = [
    {
        title: 'cube.defaultTimeQueries.custom',
        dateRange: 'custom',
    },
    {
        title: 'cube.defaultTimeQueries.allTime',
        dateRange: null,
    },
    // today
    {
        title: 'cube.defaultTimeQueries.today',
        dateRange: 'Today',
    },
    // yesterday
    {
        title: 'cube.defaultTimeQueries.yesterday',
        dateRange: 'Yesterday',
    },
    // this week
    {
        title: 'cube.defaultTimeQueries.thisWeek',
        dateRange: 'This week',
    },
    // this month
    {
        title: 'cube.defaultTimeQueries.thisMonth',
        dateRange: 'This month',
    },
    // this quarter
    {
        title: 'cube.defaultTimeQueries.thisQuarter',
        dateRange: 'This quarter',
    },
    // this year
    {
        title: 'cube.defaultTimeQueries.thisYear',
        dateRange: 'This year',
    },
    // last 7 days
    {
        title: 'cube.defaultTimeQueries.last7Days',
        dateRange: 'Last 7 days',
    },
    // last 30 days
    {
        title: 'cube.defaultTimeQueries.last30Days',
        dateRange: 'Last 30 days',
    },
    // last week
    {
        title: 'cube.defaultTimeQueries.lastWeek',
        dateRange: 'Last week',
    },
    // last month
    {
        title: 'cube.defaultTimeQueries.lastMonth',
        dateRange: 'Last month',
    },
    // last quarter
    {
        title: 'cube.defaultTimeQueries.lastQuarter',
        dateRange: 'Last quarter',
    },
    // last year
    {
        title: 'cube.defaultTimeQueries.lastYear',
        dateRange: 'Last year',
    },
];

export const useCube = () => {
    const authStore = useAuthStore();
    const $alert = useAlert();

    const cube = cubejs('', {
        apiUrl: import.meta.env.VITE_CUBE_URL as string,
        headers: {
            Authorization: `Bearer ${authStore.getToken}`,
        },
    });
    const cubes = ref<Cube[]>([]);
    const lastUpdatedAt = ref<number>(0);
    const isLoading = ref(false);

    const { t } = useI18n();

    async function getMeta() {
        isLoading.value = true;
        try {
            const data = await cube.meta();
            cubes.value = data.cubes;
            lastUpdatedAt.value = Date.now();
        } catch (error: any) {
            $alert.showAlert({
                title: t('shared.errorOcurred'),
                text: _.get(error, 'response.error.message', _.get(error, 'message', t('shared.unknown_error'))),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    }

    getMeta();

    const measures = computed(() => {
        return cubes.value
            .map((cube) => cube.measures)
            .flat()
            .filter((measure) => measure.public === true)
            .map((measure) => ({
                ...measure,
                title: t(`cube.cubes.${measure.name}`),
            }))
            .sort((a, b) => a.title.localeCompare(b.title));
    });

    const dimensions = computed(() => {
        return cubes.value
            .map((cube) => cube.dimensions)
            .flat()
            .filter((dimension) => dimension.public === true && dimension.type !== 'time')
            .map((dimension) => ({
                ...dimension,
                title: t(`cube.cubes.${dimension.name}`),
            }))
            .sort((a, b) => a.title.localeCompare(b.title));
    });

    const segments = computed(() => {
        return cubes.value
            .map((cube) => cube.segments)
            .flat()
            .filter((segment) => segment.public === true)
            .map((segment) => ({
                ...segment,
                title: t(`cube.cubes.${segment.name}`),
            }))
            .sort((a, b) => a.title.localeCompare(b.title));
    });

    const timeDimensions = computed(() => {
        return cubes.value
            .map((cube) => cube.dimensions)
            .flat()
            .filter((d) => d.public === true && d.type === 'time')
            .map((dimension) => ({
                ...dimension,
                title: t(`cube.cubes.${dimension.name}`),
            }))
            .sort((a, b) => a.title.localeCompare(b.title));
    });

    //
    const timeGranularity = computed(() => {
        return Object.values(TimeDimensionGranularity).map((granularity) => ({
            value: granularity,
            title: t(`cube.timeGranularity.${granularity}`),
        }));
    });
    const binaryOperators = computed(() => {
        return Object.values(BinaryOperator).map((operator) => ({
            value: operator,
            title: t(`cube.binaryOperators.${operator}`),
        }));
    });
    const unaryOperators = computed(() => {
        return Object.values(UnaryOperator).map((operator) => ({
            value: operator,
            title: t(`cube.unaryOperators.${operator}`),
        }));
    });

    const dateRanges = computed(() =>
        defaultTimeQueries.map((dateRange) => {
            return {
                ...dateRange,
                title: t(dateRange.title),
            };
        }),
    );

    const filterMembers = computed(() => {
        return [...measures.value, ...dimensions.value, ...timeDimensions.value, ...segments.value];
    });

    return {
        cube,
        getMeta,
        measures,
        dimensions,
        segments,
        timeDimensions,
        timeGranularity,
        binaryOperators,
        unaryOperators,
        dateRanges,
        isLoading,
        filterMembers,
    };
};
