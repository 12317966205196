// Utilities
import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        _isSidebarOpen: false,
        locale: '',
    }),
    getters: {
        isSidebarOpen(): boolean {
            return this._isSidebarOpen;
        },
        getLocale(): string {
            return this.locale;
        },

    },
    actions: {
        setIsSidebarOpen(value: boolean) {
            this._isSidebarOpen = value;
        },
        setLocale(value: string) {
            this.locale = value;
        },
    },
    persist: {
        paths: ['locale'],
    },
});
