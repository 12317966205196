<template>
    <h1 class="auth-title">{{ $t('auth.welcomeBack') }}</h1>
    <v-form
        ref="form"
        @submit.prevent="onSubmit"
        class="auth-form">
        <v-text-field
            v-model.trim="email"
            class="auth-input"
            :rules="[rules.required, rules.email]"
            type="email"
            name="email"
            :placeholder="$t('fields.email')"></v-text-field>
        <v-text-field
            v-model="password"
            class="auth-input"
            name="current-password"
            :rules="[rules.required]"
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="$t('fields.password')"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>

        <div v-if="errorMessage">
            <v-alert
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>

        <v-btn
            :loading="isLoading"
            type="submit"
            color="primary"
            class="auth-btn"
            >{{ $t('auth.login') }}</v-btn
        >

        <router-link
            class="tw-text-center tw-underline"
            :to="{ name: 'ForgotPassword' }"
            >{{ $t('auth.forgotPassword') }}</router-link
        >
    </v-form>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useError } from '@/composables/useError';

    const authStore = useAuthStore();
    const $router = useRouter();
    const $t = useI18n().t;
    const $error = useError();

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const isPasswordVisible = ref(false);

    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');

    async function onSubmit() {
        if (!form.value) return;

        errorMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            const { data } = await axios.post('/auth/local', {
                identifier: email.value,
                password: password.value,
            });

            authStore.setToken(data.jwt);
            authStore.setUser(data.user);

            $router.push('/');
        } catch (error) {
            errorMessage.value = $error.getErrorMessage(error, 'auth.errors');
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style></style>
