import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/translation';
export const API_REF = ContentTypesUID.Translation;

export async function getTranslations(query: APIRequestParams<'Translation'> = {}): AxiosPromise<APIResponseSingle<'Translation'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}
